<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('videos.unpublished') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="media" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'
export default {
  name: "UnpublishedVideos",
  components : { NavBar, MediaGridCMS },
  data: function() {
    return {
      media: [],
      showOverlay: true
    }
  },
  created: function() {
    this.$http.get('/cms/api/media/video/unpublished/', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.media = r.data.content;
    }).catch(e => { this.handleError(e); });
    this.showOverlay = false;
  }
}
</script>
<style></style>
